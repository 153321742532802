import { TokenDecoded } from 'src/app/store/auth/auth.model';
import jwt_decode from 'jwt-decode';
export const tokenExpired = (): boolean => {
  const exp = tokenDecoded()?.exp;
  return Math.floor(new Date().getTime() / 1000) >= exp;
};
export const tokenDecoded = (): TokenDecoded => {
  if (!localStorage.getItem('currentUser')) {
    return null;
  }
  return jwt_decode(localStorage.getItem('currentUser'));
};

export const lang = (): string => {
  return localStorage.getItem('lang');
};

export const createDownloadFile = (data, filename) => {
  const blob = new Blob([data], { type: data.type });
  const url = window.URL.createObjectURL(blob);
  window.open(url);
};

type Lang = 'th' | 'en';
