import { NgxSpinnerService } from 'ngx-spinner';
import {
  Component,
  ElementRef,
  OnInit,
  ViewChild,
} from '@angular/core';
import { User } from './shared/models/user.models';
import { NavbarService } from './core/components/navbar/navbar.service';
import { ThemeService } from './shared/service/theme.service';
import {
  Actions,
  ofActionDispatched,
  Select,
  Store,
} from '@ngxs/store';
import { GetUserById, Logout } from './store/auth/auth.actions';
import { Router } from '@angular/router';
import { AuthState } from './store/auth/auth.state';
import { BehaviorSubject, Observable } from 'rxjs';
import { AuthStateModel } from './store/auth/auth.model';
import { TranslateService } from '@ngx-translate/core';
import {
  SiteConfigObj,
  SiteConfigService,
} from './shared/service/site-config-service';
import { featureFlag } from 'src/environments/environment';
import { finalize, tap } from 'rxjs/operators';
import { ThemeInfo } from './modules/setting/component/theme/shared/theme.models';
import { setTheme } from 'ngx-bootstrap/utils';
import { DEFAULT_INTERRUPTSOURCES, Idle } from '@ng-idle/core';
import { Keepalive } from '@ng-idle/keepalive';
import { AlertService } from './core/services/alert.service';
import { TokenService } from './core/authentication/token.service';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  title = 'app';
  currentUser: User;
  navbarActive = false;
  defaultLang = 'en';
  loadingSuccess$ = new BehaviorSubject<boolean>(false);
  countdown?: number = null;

  @ViewChild('sessionTimoutModal', { static: true })
  sessionTimoutModal: ElementRef;
  modal: NgbModalRef;

  @Select(AuthState.token) userList$: Observable<AuthStateModel>;

  constructor(
    private router: Router,
    private spinnerService: NgxSpinnerService,
    private navbar: NavbarService,
    private themeService: ThemeService,
    private siteConfigService: SiteConfigService,
    private actions: Actions,
    private store: Store,
    private translateService: TranslateService,
    private idle: Idle,
    private keepalive: Keepalive,
    private alertService: AlertService,
    private tokenService: TokenService,
    private modalService: NgbModal,
  ) {
    setTheme('bs4');
    this.actions.pipe(ofActionDispatched(Logout)).subscribe(() => {
      this.router.navigate(['/login']);
    });
    this.initializeLanguage();

    // make sure that site setting is loaded before showing website.
    this.getSiteConfig().then(() => {
      this.getTheme().subscribe(() => {
        this.navbar.data.subscribe((res) => {
          this.navbarActive = res;
        });
      });
    });
    siteConfigService.loadLoanConfig();

    // timeout -- 60 minutes
    idle.setIdle(60 * 60);
    idle.setTimeout(15);
    idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);
    idle.onIdleStart.subscribe(() => {
      if (!featureFlag.ndid) {
        this.reset();
        return;
      }

      if (this.router.url.includes('login')) {
        this.reset();
        return;
      }

      this.modal = this.modalService.open(this.sessionTimoutModal, {
        centered: true,
        backdrop: 'static',
        keyboard: false,
      });
    });
    idle.onTimeout.subscribe(() => {
      this.logout();
    });
    idle.onTimeoutWarning.subscribe((seconds) => {
      this.countdown = seconds;
    });
    keepalive.interval(4 * 60);
    keepalive.onPing.subscribe(() => this.refreshToken());
  }

  reset(): void {
    // we'll call this method when we want to start/reset the idle process
    // reset any component state and be sure to call idle.watch()
    this.idle.watch();
    this.countdown = null;
  }

  ngOnInit(): void {
    if (
      !this.store.selectSnapshot<string>(
        (state) => state.auth.access,
      ) &&
      this.navbarActive
    ) {
      this.store.dispatch(GetUserById);
    }
    // right when the component initializes, start reset state and start watching
    this.reset();
    this.refreshToken();
  }

  /**
   * Set the initial language in localStorage
   * that will be used to stuff in HTTP Headers - `Accept-Language`
   * and for translation.
   */
  initializeLanguage(): void {
    this.defaultLang = localStorage.getItem('lang');
    if (!this.defaultLang || this.defaultLang === 'null') {
      localStorage.setItem('lang', 'en');
      this.translateService.setDefaultLang('en');
    }
    this.defaultLang = localStorage.getItem('lang');
    this.translateService.use(this.defaultLang);
  }

  getTheme(): Observable<ThemeInfo> {
    this.loadingSuccess$.next(false);
    this.spinnerService.show();
    return this.themeService.getTheme().pipe(
      tap((theme) => {
        this.themeService.setThemeList(theme);
      }),
      finalize(() => {
        this.loadingSuccess$.next(true);
        this.spinnerService.hide();
      }),
    );
  }

  getSiteConfig(): Promise<void> {
    return new Promise((resolve) => {
      this.siteConfigService
        .getConfig()
        .subscribe((config: SiteConfigObj) => {
          featureFlag.ddoc = config.ddoc_enable;
          featureFlag.login_adfs_enable = config.login_adfs_enable;
          featureFlag.ndid = config.ndid_enable;
          featureFlag.sms_otp = config.sms_otp_enable;
          featureFlag.email_otp = config.email_otp_enable;
          featureFlag.thaicom = config.thaicom_enable;
          resolve();
        });
    });
  }

  refreshToken(): void {
    const token = this.tokenService.getRefreshToken();
    if (token) {
      this.tokenService.refreshToken(token).subscribe((tk: any) => {
        this.tokenService.saveToken(tk.access);
      });
    }
  }

  logout(): void {
    this.dismissAll();
    this.reset();
    this.store.dispatch(Logout);
  }

  dismissAll(): void {
    this.modal.dismiss();
  }
}
