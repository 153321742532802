import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NavbarService } from './navbar.service';
import { NotificationService } from '../../../shared/service/notification.service';
import { TitleService } from 'src/app/shared/service/title-service';
import {
  ThemeList,
  ThemeService,
} from 'src/app/shared/service/theme.service';
import {
  LangChangeEvent,
  TranslateService,
} from '@ngx-translate/core';
import { SweetAlertService } from 'src/app/shared/service/sweet-alert.service';
import * as _ from 'lodash';
import * as $ from 'jquery';
import { Observable, Subscription } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { Logout } from '../../../store/auth/auth.actions';
import { AlertService } from '../../services/alert.service';
import { Store } from '@ngxs/store';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss'],
})
export class NavbarComponent implements OnInit {
  navbarActive = false;
  photo: string;
  name: string;
  showUser = false;
  showLogo = false;
  fullWidth: number;
  flags;
  selectedFlags: string;
  titleSubject: Subscription;
  title: string;
  isAdmin = false;
  notificationResult;
  notificationHeader: string;
  showNotificationToggle = false;
  showLang = false;
  translateSidebar;
  navbarTitle$: Observable<string>;
  page = 1;
  pageSize = 20;
  isLoading = false;
  typeNotification = {
    tag_comment_on_memo: 'memo_comment_tag',
    comment_reply_on_memo: 'memo_comment_reply',
    new_announcement: 'memo_announced',
    new_post_department: 3,
    new_post_public: 4,
    new_post_and_mention: 5,
    new_comment: 6,
    new_comment_mention: 7,
    new_comment_tag_comment: 8,
    memo_new_req_for_approval: 9,
    memo_update_status: 10,
  };

  checkLanguage = false;
  themeList: ThemeList;
  hoverList = {
    lang: false,
    notification: false,
  };

  @Input() profile;

  constructor(
    private titleService: TitleService,
    private router: Router,
    private translate: TranslateService,
    private notificationService: NotificationService,
    private swal: SweetAlertService,
    private themeService: ThemeService,
    private navbar: NavbarService,
    private alert: AlertService,
    private store: Store,
  ) {
    // this.titleSubject = this.titleService.getTitle().subscribe(title => {
    //   this.title = title.title;
    // });
    this.themeService.data.subscribe((theme) => {
      this.themeList = theme;
    });
    this.navbar.setNavbarTitle(
      this.navbar.navBarNameList.find((nav) => {
        const regex = new RegExp(`^${nav.url}`);
        return this.router.url.replace('/', '').match(regex);
      })?.name,
    );

    this.router.events.subscribe(() => {
      this.navbar.setNavbarTitle(
        this.navbar.navBarNameList.find((nav) => {
          const regex = new RegExp(`^${nav.url}`);
          return this.router.url.replace('/', '').match(regex);
        })?.name,
      );
    });
    this.navbarTitle$ = this.navbar.getNavbarTitle();
  }

  ngOnInit(): void {
    this.photo = localStorage.getItem('photoUrl');
    this.name = localStorage.getItem('name');
    this.isAdmin = localStorage.getItem('role_name') === 'Admin';
    this.showUser = true;
    this.fullWidth = document.documentElement.clientWidth;
    this.showLogo = this.fullWidth < 992;
    this.flags = [
      { label: 'English', value: 'en' },
      { label: 'Thai', value: 'th' },
    ];
    this.selectedFlags = localStorage.getItem('lang');
    this.getAllNotification();
    this.translateNotification();
    this.translate.onLangChange.subscribe(
      (event: LangChangeEvent) => {
        this.translateNotification();
      },
    );
    this.translateSidebar = this.translate;
    $('#menu-toggle').click((e) => {
      e.preventDefault();
      $('#wrapper').toggleClass('toggled');
    });
    this.navbar.data.subscribe((res) => {
      setTimeout(() => {
        this.navbarActive = res;
      });
    });
  }

  translateNotification(): void {
    this.notificationHeader = this.translate.instant(
      'NAV-BAR.NOTIFICATION',
    );

    if (this.translate.currentLang === 'en') {
      this.checkLanguage = true;
    } else {
      this.checkLanguage = false;
    }
  }

  getAllNotification(): void {
    this.notificationService
      .getAllNotification({
        page: 1,
        page_size: this.pageSize,
        order_by_date_only: true,
      })
      .subscribe(
        (notification) => {
          this.notificationResult = notification;
        },
        (err) => {
          this.swal.toastNotification({
            type: 'error',
            content: err.status,
          });
        },
      );
  }

  canCreateUser(): boolean {
    return this.router.url === '/permission/profile/list';
  }

  openNotification(): void {
    this.showNotificationToggle = !this.showNotificationToggle;
  }

  closeNotification(): void {
    this.showNotificationToggle = false;
  }

  markAllAsRead(): void {
    this.notificationService.markAllAsRead().subscribe(() => {
      this.notificationResult.unread_count = 0;
      this.notificationResult.results.forEach((notification) => {
        notification.read = true;
      });
    });
  }

  // Notification
  clickNotification(notification): void {
    this.notificationService.updateIsRead(notification.id).subscribe(
      () => {
        _.forEach(this.notificationResult.results, (obj) => {
          if (notification.id === obj.id && obj.read === false) {
            obj.read = true;
            this.notificationResult.unread_count =
              this.notificationResult.unread_count - 1;
          }
        });

        this.notificationRouter(notification);
      },
      (err) => {
        this.swal.toastNotification({
          type: 'error',
          content: err.status,
        });
      },
    );
  }

  notificationRouter(notification): void {
    this.closeNotification();
    if (
      notification.notification.source_content_type_name === 'memo'
    ) {
      if (notification.notification.action === 'memo_pending') {
        return this.navigateToPage(
          '/pending-approval/',
          notification.notification.source_id,
        );
      } else {
        return this.navigateToPage(
          '/memos/preview/',
          notification.notification.source_id,
        );
      }
    } else if (
      notification.notification.source_content_type_name ===
      'announcement'
    ) {
      this.router.navigate([
        '/news-feed',
        'acknowledge',
        notification.notification.source_id,
      ]);
    } else if (
      notification.notification.source_content_type_name ===
      'newsfeedpost'
    ) {
      this.navigateToPage(
        '/news-feed/',
        notification.notification.source_id,
      );
    }
  }

  navigateToPage(url: string, id: number): void {
    this.router
      .navigateByUrl('/', { skipLocationChange: true })
      .then(() => {
        this.router.navigate([url + id]);
      });
  }

  loadMoreNotification(): void {
    if (this.notificationResult.next && !this.isLoading) {
      this.isLoading = true;
      this.page++;

      const params = {
        page: this.page,
        page_size: this.pageSize,
        order_by_date_only: true,
      };
      this.notificationService
        .getAllNotification(params)
        .pipe(
          finalize(() => {
            this.isLoading = false;
          }),
        )
        .subscribe((res) => {
          const oldResult = this.notificationResult.results;

          this.notificationResult = res;
          this.notificationResult.results = oldResult.concat(
            res.results,
          );
        });
    }
  }

  hoverText(type: string, value: boolean): void {
    this.hoverList[type] = value;
  }

  changeLanguage(lang: string): void {
    if (lang === 'en') {
      this.translate.use('en');
      localStorage.setItem('lang', 'en');
    } else if (lang === 'th') {
      this.translate.use('th');
      localStorage.setItem('lang', 'th');
    }
  }

  sidebar(): void {
    this.titleService.setSidebar();
  }

  logOut(): void {
    this.alert
      .confirm(this.translate.instant('SIDEBAR.CONTENT'))
      .then((res) => {
        if (res.value) {
          this.store.dispatch(Logout);
        }
      });
  }

  navigateToProfile(): void {
    this.router.navigate(['/', 'profile-detail', 'detail']);
  }

  get language(): string {
    return localStorage.getItem('lang');
  }
}
