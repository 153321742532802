<div class="d-flex"
    id="wrapper">
  <!-- Sidebar -->
  <div class="width-sidebar"
      id="sidebar-wrapper"
      [ngClass]="openSidebar? 'position-sidebar':'hidden-sidebar'"
      [ngStyle]="{
        background:
          'linear-gradient(to top, ' +
          themeList?.bg_color_primary +
          ', ' +
          themeList?.bg_color_secondary +
          ' 60%)',
        color: themeList?.text_color
      }">
    <div class="sidebar-heading d-flex">
      <div class="flex-grow-1">
        <img [src]="themeList?.display_sidebar? (themeList.display_sidebar | secure | async): 'assets/images/codium_logo.png' "
            style="max-height: 50px;"
            [ngStyle]="{'opacity': isFullSidebar? '10':'0'}">
      </div>
      <span class="show-close pointer pr-4 text-right"
          (click)="openSidebar = !openSidebar"><i class="fas fa-times"></i></span>
    </div>

    <!-- /#sidebar-wrapper -->
    <div class="list-group list-group-flush pt-4"
        [ngClass]="isFullSidebar ? 'text-left':'text-center'">

      <div class="menu-main overflow-auto">
        <!-- News Feed -->
        <div routerLinkActive="active"
            [routerLink]="['/', 'news-feed']"
            class="mt-2"
            #rla="routerLinkActive"
            [style.--color]="themeList?.hovered_text_color"
            [style.--bg]="themeList?.hovered_text_color + '20'"
            ngbTooltip="{{'SIDEBAR.NEWS-FEED' | translate}}"
            placement="right">
          <a class="list-group-item list-group-item-action px-4">
            <svg-icon src="assets/svg/icon-menu/blog.svg"
                [svgStyle]="{
                  'fill': rla.isActive
                    ? themeList?.hovered_text_color
                    : themeList?.text_color,
                  'height.px': 20,
                  'width.px': 20
                }">
            </svg-icon>
            <span class="pl-2 f-size-17"
                *ngIf="isFullSidebar"
                [ngStyle]="{
                  color: rla.isActive
                    ? themeList?.hovered_text_color
                    : themeList?.text_color
                }">
              {{'SIDEBAR.NEWS-FEED'|translate}}
            </span>
          </a>
        </div>

        <!-- Approval -->
        <div routerLinkActive="active"
            [routerLink]="['/', 'pending-approval']"
            #rla="routerLinkActive"
            [style.--color]="themeList?.hovered_text_color"
            [style.--bg]="themeList?.hovered_text_color + '20'"
            *appCheckPermission="['General']"
            ngbTooltip="{{'SIDEBAR.PENDING' | translate}}"
            placement="right">
          <a class="list-group-item list-group-item-action notification px-4">
            <svg-icon src="assets/svg/icon-menu/document-tasks.svg"
                [stretch]="true"
                [svgStyle]="{
                  'fill': rla.isActive
                    ? themeList?.hovered_text_color
                    : themeList?.text_color,
                  'height.px': 20,
                  'width.px': 20
                }">
            </svg-icon>
            <span class="pl-2 f-size-17"
                *ngIf="isFullSidebar"
                [ngStyle]="{
                  color: rla.isActive
                    ? themeList?.hovered_text_color
                    : themeList?.text_color
                }">
              {{'SIDEBAR.PENDING'|translate}}
            </span>
            <span class="badge-number d-inline-block"
                *ngIf="notificationService.memoNotificationCount > 0">
              {{ notificationService.memoNotificationCount > 99 ? '99+' : notificationService.memoNotificationCount }}
            </span>
          </a>
        </div>

        <!-- Management -->
        <div class="custom-border custom-border-top"
            *appCheckPermission="['Admin', 'Master Admin', 'IT']"
            [ngStyle]="{'background': departmentSubMenu? '': '' , 'color': themeList?.text_color}">
          <div routerLinkActive="active"
              class="mt-2 hasSubMenu"
              *appCheckPermission="['Admin', 'Master Admin', 'IT']"
              [style.--color]="themeList?.hovered_text_color"
              [style.--bg]="themeList?.hovered_text_color + '20'"
              placement="right"
              (click)="departmentSubMenu=!departmentSubMenu">
            <a class="list-group-item list-group-item-action px-4 position-relative d-flex">
              <span class="w-100 f-size-17 pt-1"
                  *ngIf="isFullSidebar"
                  [ngStyle]="{'color': themeList?.text_color}">{{'SIDEBAR.MANAGEMENT'|translate}}
              </span>
              <div class="text-right"
                  [ngStyle]="{'color': themeList?.text_color}">
                <i class="far"
                   [ngClass]=" departmentSubMenu ? 'fa-angle-up' : 'fa-angle-down'"></i>
              </div>
            </a>
          </div>

          <!-- Department level -->
          <ng-container *ngIf="departmentSubMenu">
            <div routerLinkActive="active"
                [routerLink]="['/', 'department', 'level']"
                #rla="routerLinkActive"
                [style.--color]="themeList?.hovered_text_color"
                [style.--bg]="themeList?.hovered_text_color + '20'"
                *appCheckPermission="['Admin', 'Master Admin', 'IT']"
                ngbTooltip="{{'SIDEBAR.DEPARTMENT-LEVEL' | translate}}"
                placement="right">
              <a class="list-group-item list-group-item-action px-4">

                <svg-icon src="assets/svg/icon-menu/layer-group-solid.svg"
                    [stretch]="true"
                    [svgStyle]="{
                      'fill': rla.isActive
                        ? themeList?.hovered_text_color
                        : themeList?.text_color,
                      'height.px': 15,
                      'width.px': 15
                    }">
                </svg-icon>
                <span class="pl-2 f-size-17"
                    *ngIf="isFullSidebar"
                    [ngStyle]="{
                      color: rla.isActive
                        ? themeList?.hovered_text_color
                        : themeList?.text_color
                    }">
                  {{'SIDEBAR.DEPARTMENT-LEVEL' | translate}}
                </span>
              </a>
            </div>

            <!-- Department -->
            <div routerLinkActive="active"
                [routerLink]="['/', 'department', 'add']"
                #rla="routerLinkActive"
                [style.--color]="themeList?.hovered_text_color"
                [style.--bg]="themeList?.hovered_text_color + '20'"
                *appCheckPermission="['Admin', 'Master Admin', 'IT']"
                ngbTooltip="{{'SIDEBAR.DEPARTMENT' | translate}}"
                placement="right">
              <a class="list-group-item list-group-item-action px-4">
                <!-- Department -->
                <svg-icon src="assets/svg/icon-menu/events.svg"
                    [stretch]="true"
                    [svgStyle]="{ 'fill': (rla.isActive ?
                        themeList?.hovered_text_color: themeList?.text_color), 'height.px': 20, 'width.px': 20}">
                </svg-icon>
                <span class="pl-2 f-size-17"
                    *ngIf="isFullSidebar"
                    [ngStyle]="{
                      color: rla.isActive
                        ? themeList?.hovered_text_color
                        : themeList?.text_color
                    }">
                  {{'SIDEBAR.DEPARTMENT' | translate}}
                </span>
              </a>
            </div>

            <!-- Manage Users -->
            <div routerLinkActive="active"
                [routerLink]="['/', 'users']"
                #rla="routerLinkActive"
                [style.--color]="themeList?.hovered_text_color"
                [style.--bg]="themeList?.hovered_text_color + '20'"
                *appCheckPermission="['Admin', 'Master Admin', 'IT']"
                ngbTooltip="{{'SIDEBAR.MANAGE-USERS' | translate}}"
                placement="right">
              <a class="list-group-item list-group-item-action px-4">
                <!-- Manage Users -->
                <svg-icon src="assets/svg/icon-menu/user--profile.svg"
                    [stretch]="true"
                    [svgStyle]="{
                      'fill': rla.isActive
                        ? themeList?.hovered_text_color
                        : themeList?.text_color,
                      'height.px': 20,
                      'width.px': 20
                    }">
                </svg-icon>
                <span class="pl-2 f-size-17"
                    *ngIf="isFullSidebar"
                    [ngStyle]="{
                      color: rla.isActive
                        ? themeList?.hovered_text_color
                        : themeList?.text_color
                    }">
                  {{'SIDEBAR.MANAGE-USERS'|translate}}
                </span>
              </a>
            </div>

            <!-- LOA -->
            <div routerLinkActive="active"
                [routerLink]="['/', 'loa']"
                #rla="routerLinkActive"
                [style.--color]="themeList?.hovered_text_color"
                [style.--bg]="themeList?.hovered_text_color + '20'"
                *appCheckPermission="['Admin', 'Master Admin', 'IT']"
                ngbTooltip="{{'SIDEBAR.MANAGE-APPROVAL' | translate}}"
                placement="right">
              <a class="list-group-item list-group-item-action px-4">
                <svg-icon src="assets/svg/icon-menu/category.svg"
                    [stretch]="true"
                    [svgStyle]="{
                      'fill': rla.isActive
                        ? themeList?.hovered_text_color
                        : themeList?.text_color,
                      'height.px': 20,
                      'width.px': 20
                    }">
                </svg-icon>
                <span class="pl-2 f-size-17"
                    *ngIf="isFullSidebar"
                    [ngStyle]="{
                      color: rla.isActive
                        ? themeList?.hovered_text_color
                        : themeList?.text_color
                    }">
                  {{'SIDEBAR.MANAGE-APPROVAL'|translate}}
                </span>
              </a>
            </div>

            <!-- CC Group -->
            <div routerLinkActive="active"
                [routerLink]="['/', 'cc-group']"
                #rla="routerLinkActive"
                [style.--color]="themeList?.hovered_text_color"
                [style.--bg]="themeList?.hovered_text_color + '20'"
                *appCheckPermission="['Admin', 'Master Admin', 'IT']"
                ngbTooltip="{{'SIDEBAR.MANAGE-CC-GROUP' | translate}}"
                placement="right">
              <a class="list-group-item list-group-item-action px-4">
                <svg-icon src="assets/svg/icon-menu/cc.svg"
                    [svgStyle]="{
                      'fill': rla.isActive
                        ? themeList?.hovered_text_color
                        : themeList?.text_color,
                      'height.px': 20,
                      'width.px': 20
                    }">
                </svg-icon>
                <span class="pl-2 f-size-17"
                    *ngIf="isFullSidebar"
                    [ngStyle]="{
                      color: rla.isActive
                        ? themeList?.hovered_text_color
                        : themeList?.text_color
                    }">
                  {{'SIDEBAR.MANAGE-CC-GROUP'|translate}}
                </span>
              </a>
            </div>

            <!-- All Document (Trash) -->
            <div routerLinkActive="active"
                [routerLink]="['/', 'memos', 'trash']"
                #rla="routerLinkActive"
                [style.--color]="themeList?.hovered_text_color"
                [style.--bg]="themeList?.hovered_text_color + '20'"
                *appCheckPermission="['Admin', 'Master Admin']"
                ngbTooltip="{{'SIDEBAR.ALL-DOCUMENTS' | translate}}"
                placement="right">
              <a class="list-group-item list-group-item-action px-4">
                <svg-icon src="assets/svg/icon-menu/document-tasks.svg"
                    [stretch]="true"
                    [svgStyle]="{
                      'fill': rla.isActive
                        ? themeList?.hovered_text_color
                        : themeList?.text_color,
                      'height.px': 20,
                      'width.px': 20
                    }">
                </svg-icon>
                <span class="pl-2 f-size-17"
                    *ngIf="isFullSidebar"
                    [ngStyle]="{
                      color: rla.isActive
                        ? themeList?.hovered_text_color
                        : themeList?.text_color
                    }">
                  {{'SIDEBAR.ALL-DOCUMENTS'|translate}}
                </span>
              </a>
            </div>

          </ng-container>
        </div>

        <!-- Manage Upload Memo     -->
        <div class="custom-border"
            *appCheckPermission="['Admin', 'Master Admin']"
            [ngStyle]="{'background': manageUploadSubMenu? '': '' , 'color': themeList?.text_color}">
          <!-- manage types upload memo -->
          <div routerLinkActive="active"
              class="mt-2 hasSubMenu"
              *appCheckPermission="['Admin', 'Master Admin']"
              [style.--color]="themeList?.hovered_text_color"
              [style.--bg]="themeList?.hovered_text_color + '20'"
              placement="right"
              (click)="manageUploadSubMenu=!manageUploadSubMenu">
            <a class="list-group-item list-group-item-action px-4 position-relative d-flex">
              <span class="w-100 f-size-17 pt-1"
                  *ngIf="isFullSidebar"
                  [ngStyle]="{'color': themeList?.text_color}">{{'SIDEBAR.MEMO-UPLOAD-TYPE-MENU'|translate}}</span>
              <div class="text-right"
                  [ngStyle]="{'color': themeList?.text_color}">
                <i class="far"
                   [ngClass]=" manageUploadSubMenu ? 'fa-angle-up' : 'fa-angle-down'"></i>
              </div>
            </a>
          </div>

          <!-- manage types upload memo type -->
          <ng-container *ngIf="manageUploadSubMenu">
            <div routerLinkActive="active"
                [routerLink]="['/', 'manage-types-upload-memo', 'type']"
                #rla="routerLinkActive"
                [style.--color]="themeList?.hovered_text_color"
                [style.--bg]="themeList?.hovered_text_color + '20'"
                *appCheckPermission="['Admin', 'Master Admin']"
                ngbTooltip="{{'SIDEBAR.ADD-TYPE' | translate}}"
                placement="right">
              <a class="list-group-item list-group-item-action px-4">
                <svg-icon src="assets/svg/icon-menu/catalog.svg"
                    [stretch]="true"
                    [svgStyle]="{ 'fill': (rla.isActive ?
                        themeList?.hovered_text_color: themeList?.text_color), 'height.px': 20, 'width.px': 20}">
                </svg-icon>
                <span class="pl-2 f-size-17"
                    *ngIf="isFullSidebar"
                    [ngStyle]="{
                      color: rla.isActive
                        ? themeList?.hovered_text_color
                        : themeList?.text_color
                    }">
                  {{'SIDEBAR.ADD-TYPE' | translate}}
                </span>
              </a>
            </div>
          </ng-container>

          <!-- manage types upload memo template -->
          <ng-container *ngIf="manageUploadSubMenu">
            <div routerLinkActive="active"
                [routerLink]="['/', 'manage-types-upload-memo', 'template']"
                #rla="routerLinkActive"
                [style.--color]="themeList?.hovered_text_color"
                [style.--bg]="themeList?.hovered_text_color + '20'"
                *appCheckPermission="['Admin', 'Master Admin']"
                ngbTooltip="{{'SIDEBAR.ADD-TEMPLATE' | translate}}"
                placement="right">
              <a class="list-group-item list-group-item-action px-4">
                <svg-icon src="assets/svg/icon-menu/document--pdf.svg"
                    [stretch]="true"
                    [svgStyle]="{ 'fill': (rla.isActive ?
                        themeList?.hovered_text_color: themeList?.text_color), 'height.px': 20, 'width.px': 20}">
                </svg-icon>
                <span class="pl-2 f-size-17"
                    *ngIf="isFullSidebar"
                    [ngStyle]="{
                      color: rla.isActive
                        ? themeList?.hovered_text_color
                        : themeList?.text_color
                    }">
                  {{'SIDEBAR.ADD-TEMPLATE' | translate}}
                </span>
              </a>
            </div>
          </ng-container>
        </div>

        <!-- Certificate -->
        <ng-container *appFeatureFlag="'ddoc'">
          <div class="custom-border"
              *appCheckPermission="['Admin', 'Master Admin', 'IT']"
              [ngStyle]="{'background': manageCertificate? '': '' , 'color': themeList?.text_color}">
            <!-- manage certificate -->
            <div routerLinkActive="active"
                class="mt-2 hasSubMenu"
                *appCheckPermission="['Admin', 'Master Admin', 'IT']"
                [style.--color]="themeList?.hovered_text_color"
                [style.--bg]="themeList?.hovered_text_color + '20'"
                placement="right"
                (click)="manageCertificate=!manageCertificate">
              <a class="list-group-item list-group-item-action px-4 position-relative d-flex">
                <span class="w-100 f-size-17 pt-1"
                    *ngIf="isFullSidebar"
                    [ngStyle]="{'color': themeList?.text_color}">{{'CSR.CERTIFICATE'|translate}}</span>
                <div class="text-right"
                    [ngStyle]="{'color': themeList?.text_color}">
                  <i class="far"
                     [ngClass]=" manageCertificate ? 'fa-angle-up' : 'fa-angle-down'"></i>
                </div>
              </a>
            </div>

            <!-- manage csr -->
            <ng-container *ngIf="manageCertificate">
              <div routerLinkActive="active"
                  [routerLink]="['/', 'manage-csr']"
                  #rla="routerLinkActive"
                  [style.--color]="themeList?.hovered_text_color"
                  [style.--bg]="themeList?.hovered_text_color + '20'"
                  *appCheckPermission="['Admin', 'Master Admin', 'IT']"
                  ngbTooltip="{{'SIDEBAR.CSR' | translate}}"
                  placement="right">
                <a class="list-group-item list-group-item-action px-4">
                  <svg-icon src="assets/svg/icon-menu/carbon_request-quote.svg"
                      [stretch]="true"
                      [svgStyle]="{ 'fill': (rla.isActive ?
                  themeList?.hovered_text_color: themeList?.text_color), 'height.px': 20, 'width.px': 20}">
                  </svg-icon>
                  <span class="pl-2 f-size-17"
                      *ngIf="isFullSidebar"
                      [ngStyle]="{
                color: rla.isActive
                  ? themeList?.hovered_text_color
                  : themeList?.text_color
              }">
                    {{'CSR.CSR-LIST' | translate}}
                  </span>
                </a>
              </div>
            </ng-container>

            <!-- Manage Certificate -->
            <ng-container *ngIf="manageCertificate">
              <div routerLinkActive="active"
                  [routerLink]="['/', 'certificate']"
                  #rla="routerLinkActive"
                  [style.--color]="themeList?.hovered_text_color"
                  [style.--bg]="themeList?.hovered_text_color + '20'"
                  *appCheckPermission="['Admin', 'Master Admin', 'IT']"
                  ngbTooltip="{{'SIDEBAR.CERTIFICATE' | translate}}"
                  placement="right">
                <a class="list-group-item list-group-item-action px-4">
                  <!-- Manage Certificate -->
                  <svg-icon src="assets/svg/icon-menu/certificate.svg"
                      [stretch]="true"
                      [svgStyle]="{
              'fill': rla.isActive
                ? themeList?.hovered_text_color
                : themeList?.text_color,
              'height.px': 20,
              'width.px': 20
            }">
                  </svg-icon>
                  <span class="pl-2 f-size-17"
                      *ngIf="isFullSidebar"
                      [ngStyle]="{
              color: rla.isActive
                ? themeList?.hovered_text_color
                : themeList?.text_color
              }">
                    {{'SIDEBAR.CERTIFICATE'|translate}}
                  </span>
                </a>
              </div>
            </ng-container>
          </div>
        </ng-container>

        <!-- Usage Information -->
        <div class="custom-border"
            *appCheckPermission="['Admin', 'Master Admin']"
            [ngStyle]="{'background': usageInformation? '': '' , 'color': themeList?.text_color}">
          <div routerLinkActive="active"
              class="mt-2 hasSubMenu"
              *appCheckPermission="['Admin', 'Master Admin', 'IT']"
              [style.--color]="themeList?.hovered_text_color"
              [style.--bg]="themeList?.hovered_text_color + '20'"
              placement="right"
              (click)="usageInformation=!usageInformation">
            <a class="list-group-item list-group-item-action px-4 position-relative d-flex">
              <span class="w-100 f-size-17 pt-1"
                  *ngIf="isFullSidebar"
                  [ngStyle]="{'color': themeList?.text_color}">{{"SIDEBAR.USAGE-INFORMATION"|translate}}</span>
              <div class="text-right"
                  [ngStyle]="{'color': themeList?.text_color}">
                <i class="far"
                   [ngClass]=" usageInformation ? 'fa-angle-up' : 'fa-angle-down'"></i>
              </div>
            </a>
          </div>

          <!-- usage information -->
          <ng-container *ngIf="usageInformation">
            <!-- Dashboard -->
            <div routerLinkActive="active"
                [routerLink]="['/', 'kpi-dashboard']"
                #rla="routerLinkActive"
                [style.--color]="themeList?.hovered_text_color"
                [style.--bg]="themeList?.hovered_text_color + '20'"
                *appCheckPermission="['Admin', 'Master Admin']"
                ngbTooltip="{{'SIDEBAR.DASHBOARD' | translate}}"
                placement="right">
              <a class="list-group-item list-group-item-action px-4">
                <svg-icon src="assets/svg/icon-menu/dashboard.svg"
                    [stretch]="true"
                    [svgStyle]="{
                      'fill': rla.isActive
                        ? themeList?.hovered_text_color
                        : themeList?.text_color,
                      'height.px': 20,
                      'width.px': 20
                    }">
                </svg-icon>
                <span class="pl-2 f-size-17"
                    *ngIf="isFullSidebar"
                    [ngStyle]="{
                      color: rla.isActive
                        ? themeList?.hovered_text_color
                        : themeList?.text_color
                    }">
                  {{'SIDEBAR.DASHBOARD' | translate}}
                </span>
              </a>
            </div>

            <!-- Memo Report -->
            <div routerLinkActive="active"
                [routerLink]="['/', 'memo-report']"
                #rla="routerLinkActive"
                [style.--color]="themeList?.hovered_text_color"
                [style.--bg]="themeList?.hovered_text_color + '20'"
                *appCheckPermission="['Admin', 'Master Admin']"
                ngbTooltip="{{'SIDEBAR.MEMO-REPORT' | translate}}"
                placement="right">
              <a class="list-group-item list-group-item-action px-4">
                <svg-icon src="assets/svg/icon-menu/text-link--analysis.svg"
                    [stretch]="true"
                    [svgStyle]="{
                      'fill': rla.isActive
                        ? themeList?.hovered_text_color
                        : themeList?.text_color,
                      'height.px': 20,
                      'width.px': 20
                    }">
                </svg-icon>
                <span class="pl-2 f-size-17"
                    *ngIf="isFullSidebar"
                    [ngStyle]="{
                      color: rla.isActive
                        ? themeList?.hovered_text_color
                        : themeList?.text_color
                    }">
                  {{'SIDEBAR.MEMO-REPORT' | translate}}
                </span>
              </a>
            </div>

            <!-- Operation Log -->
            <div routerLinkActive="active"
                [routerLink]="['/', 'operation-log']"
                #rla="routerLinkActive"
                [style.--color]="themeList?.hovered_text_color"
                [style.--bg]="themeList?.hovered_text_color + '20'"
                *appCheckPermission="['Admin', 'Master Admin']"
                ngbTooltip="{{'SIDEBAR.OPERATION-LOG' | translate}}"
                placement="right">
              <a class="list-group-item list-group-item-action px-4">
                <svg-icon src="assets/svg/icon-menu/task--view.svg"
                    [stretch]="true"
                    [svgStyle]="{
                      'fill': rla.isActive
                        ? themeList?.hovered_text_color
                        : themeList?.text_color,
                      'height.px': 20,
                      'width.px': 20
                    }">
                </svg-icon>
                <span class="pl-2 f-size-17"
                    *ngIf="isFullSidebar"
                    [ngStyle]="{
                      color: rla.isActive
                        ? themeList?.hovered_text_color
                        : themeList?.text_color
                    }">
                  {{'SIDEBAR.OPERATION-LOG' | translate}}
                </span>
              </a>
            </div>
          </ng-container>
        </div>

        <!-- Package & Additionals -->
        <div class="custom-border"
          *appCheckPermission="['Admin', 'Master Admin']"
          [ngStyle]="{'background': packageAddition? '': '' , 'color': themeList?.text_color}">
          <div routerLinkActive="active"
            class="mt-2 hasSubMenu"
            *appCheckPermission="['Admin', 'Master Admin', 'IT']"
            [style.--color]="themeList?.hovered_text_color"
            [style.--bg]="themeList?.hovered_text_color + '20'"
            placement="right"
            (click)="packageAddition=!packageAddition">
            <a class="list-group-item list-group-item-action px-4 position-relative d-flex">
              <span class="w-100 f-size-17 pt-1"
                *ngIf="isFullSidebar"
                [ngStyle]="{'color': themeList?.text_color}">{{'SIDEBAR.PACKAGE-AND-ADDITIONAL'|translate}}</span>
              <div class="text-right"
                [ngStyle]="{'color': themeList?.text_color}">
                <i class="far"
                   [ngClass]=" packageAddition ? 'fa-angle-up' : 'fa-angle-down'"></i>
              </div>
            </a>
          </div>

          <!-- usage information -->
          <ng-container *ngIf="packageAddition">

            <!-- Package Dashboard -->
            <ng-container *appFeatureFlag="'ddoc'">
              <div routerLinkActive="active"
                [routerLink]="['/', 'usage-dashboard', 'package']"
                #rla="routerLinkActive"
                [style.--color]="themeList?.hovered_text_color"
                [style.--bg]="themeList?.hovered_text_color + '20'"
                *appCheckPermission="['Admin', 'Master Admin']"
                [ngbTooltip]="'SIDEBAR.PACKAGE-USAGE'|translate"
                placement="right">
                <a class="list-group-item list-group-item-action px-4">
                  <svg-icon src="assets/svg/icon-menu/package-usage.svg"
                    [stretch]="true"
                    [svgStyle]="{ 'fill': (rla.isActive ? themeList?.hovered_text_color: themeList?.text_color),
        'height.px': 20, 'width.px': 20}">
                  </svg-icon>
                  <span class="pl-2 f-size-17"
                    *ngIf="isFullSidebar"
                    [ngStyle]="{
                    color: rla.isActive
                      ? themeList?.hovered_text_color
                      : themeList?.text_color
                  }">
                    {{'SIDEBAR.PACKAGE-USAGE'|translate}}
                  </span>
                </a>
              </div>
            </ng-container>

            <!-- Usage Dashboard -->
            <ng-container>
              <div routerLinkActive="active"
                [routerLink]="['/', 'usage-dashboard', 'usage']"
                #rla="routerLinkActive"
                [style.--color]="themeList?.hovered_text_color"
                [style.--bg]="themeList?.hovered_text_color + '20'"
                *appCheckPermission="['Admin', 'Master Admin']"
                [ngbTooltip]="'SIDEBAR.CREDIT-USAGE'|translate"
                placement="right">
                <a class="list-group-item list-group-item-action px-4">
                  <svg-icon src="assets/svg/icon-menu/credit-usage.svg"
                    [stretch]="true"
                    [svgStyle]="{ 'fill': (rla.isActive ? themeList?.hovered_text_color: themeList?.text_color),
        'height.px': 20, 'width.px': 20}">
                  </svg-icon>
                  <span class="pl-2 f-size-17"
                    *ngIf="isFullSidebar"
                    [ngStyle]="{
                    color: rla.isActive
                      ? themeList?.hovered_text_color
                      : themeList?.text_color
                  }">
                    {{'SIDEBAR.CREDIT-USAGE'|translate}}
                  </span>
                </a>
              </div>
            </ng-container>

          </ng-container>
        </div>

        <!-- Memo -->
        <div routerLinkActive="active"
            [routerLink]="['/', 'memos']"
            #rla="routerLinkActive"
            [style.--color]="themeList?.hovered_text_color"
            [style.--bg]="themeList?.hovered_text_color + '20'"
            *appCheckPermission="['General']"
            ngbTooltip="{{'SIDEBAR.MEMOS' | translate}}"
            placement="right">
          <a class="list-group-item list-group-item-action px-4">
            <!-- Manage Users -->
            <svg-icon src="assets/svg/icon-menu/memo.svg"
                [stretch]="true"
                [svgStyle]="{
                      'fill': rla.isActive
                        ? themeList?.hovered_text_color
                        : themeList?.text_color,
                      'height.px': 20,
                      'width.px': 20
                    }">
            </svg-icon>
            <span class="pl-2 f-size-17"
                *ngIf="isFullSidebar"
                [ngStyle]="{
                  color: rla.isActive
                    ? themeList?.hovered_text_color
                    : themeList?.text_color
                }">
              {{'SIDEBAR.MEMOS' | translate}}
            </span>
          </a>
        </div>

        <!-- Config -->
        <div routerLinkActive="active"
            [routerLink]="['/', 'configuration']"
            #rla="routerLinkActive"
            [style.--color]="themeList?.hovered_text_color"
            [style.--bg]="themeList?.hovered_text_color + '20'"
            *appCheckPermission="['Admin', 'Master Admin']"
            ngbTooltip="{{'SIDEBAR.CONFIGURATION' | translate}}"
            placement="right">
          <a class="list-group-item list-group-item-action px-4">
            <svg-icon src="assets/svg/icon-menu/setting-theme.svg"
                [stretch]="true"
                [svgStyle]="{
                  'fill': rla.isActive
                    ? themeList?.hovered_text_color
                    : themeList?.text_color,
                  'height.px': 20,
                  'width.px': 20
                }">
            </svg-icon>
            <span class="pl-2 f-size-17"
                *ngIf="isFullSidebar"
                [ngStyle]="{
                  color: rla.isActive
                    ? themeList?.hovered_text_color
                    : themeList?.text_color
                }">
              {{'SIDEBAR.CONFIGURATION' | translate}}
            </span>
          </a>
        </div>
      </div>
    </div>
  </div>
  <!-- Page Content -->
  <div id="page-content-wrapper">
    <app-navbar [profile]="profile"></app-navbar>

    <ng-container *appFeatureFlag="'ddoc'">
      <div class="col-12 px-0"
          *ngIf="showWarning &&
            profile.otp_type === null &&
            (checkRouteActive('/pending-approval') ||
              checkRouteActive('/news-feed') ||
              checkRouteActive('/memos'))">
        <div class="box-warning col-12 d-flex">
          <i class="fal fa-exclamation-triangle align-self-center mr-2"
              style="color:rgb(216, 187, 24)"></i>
          <span class="align-self-center">{{ 'CERTIFICATE.OTP-NOT-ENABLE'|translate }}</span>
          <button class="btn align-self-center ml-3"
              [routerLink]="['/', 'profile-detail', 'otp']"
              appSetTheme
              type="button">
            <span style="font-size: 13px">{{'CERTIFICATE.GOTO-OTP-SETTINGS'|translate}}</span>
          </button>
          <a class="text-black p-2 align-self-center ml-auto"
              href="javascript:void(0)"
              role="button"
              (click)="showWarning = false">
            <i class="fal fa-times"></i>
          </a>
        </div>
      </div>
    </ng-container>

    <div class="container-fluid overflow-auto p-0">
      <div class="container">
        <ng-content></ng-content>
      </div>
    </div>
  </div>
</div>
