const url = window.location.href;
const matches = url.match(/^http(s?)?\:\/\/([^\/?#]+)/);
const baseUrl = matches[0];
const websocketUrl = baseUrl.replace('http', 'ws') + '/ws/';

export const environment = {
  production: false,
  baseUrl,
  websocketUrl,
};

export const featureFlag = {
  ddoc: false,
  ndid: false,
  login_adfs_enable: false,
  sms_otp: false,
  email_otp: false,
  thaicom: false,
};
