<div class="d-flex align-items-center justify-content-start my-2 old-design"
  *ngIf="!newDesign">
  <div class="mr-2 mt-1 d-none-sm">{{"MEMOS.SHOW" | translate}}</div>
  <div class="mr-2 d-none-sm">
    <ng-select [items]="paginationDropdown"
      class="page-selector custom-pagination mt-1"
      [notFoundText]="'SELECT.No items found'|translate"
      placeholder="{{pageSize}}"
      [clearable]="false"
      (change)="changePageSizeEmit($event)"></ng-select>
  </div>
  <div class="mt-1 d-none-sm">{{"MEMOS.ITEM-PER-PAGE" | translate}}</div>
  <ngb-pagination *ngIf="count"
    [collectionSize]="count"
    [(page)]="page"
    aria-label="count"
    [pageSize]="pageSize"
    [maxSize]="5"
    (pageChange)="changePageEmit()">
    <ng-template ngbPaginationPrevious>{{"PAGINATION.PREV" | translate}}</ng-template>
    <ng-template ngbPaginationNext>{{"PAGINATION.NEXT" | translate}}</ng-template>
  </ngb-pagination>
</div>

<div class="d-sm-block d-md-flex align-items-center justify-content-between my-2 new-design"
  *ngIf="newDesign"
  [style.--c]="themeList?.header_table_color"
  [style.--t]="themeList?.text_header_table_color">
  <div class="d-none d-md-flex align-items-center" *ngIf="!noneSize">
    <div class="mr-2 mt-1">{{"MEMOS.SHOW" | translate}}</div>
    <div class="mr-2">
      <ng-select [items]="paginationDropdown"
        class="mt-1"
        [notFoundText]="'SELECT.No items found'|translate"
        placeholder="{{pageSize}}"
        [clearable]="false"
        (change)="changePageSizeEmit($event)"></ng-select>
    </div>
    <div class="mt-1">{{"MEMOS.ITEM-PAGE" | translate}} {{ count }}</div>
  </div>
  <div class="mr-2 mt-1" *ngIf="noneSize">{{"UPLOAD.PAGE" | translate}}</div>
  <ngb-pagination *ngIf="count"
      [boundaryLinks]="boundaryLinks"
      [collectionSize]="count"
      [ellipses]="ellipses"
      [rotate]="rotate"
      [(page)]="page"
      aria-label="count"
      [pageSize]="pageSize"
      [maxSize]="isMobile ? 1 : maxSize"
      (pageChange)="changePageEmit()">
    <ng-template ngbPaginationPrevious>
      <svg-icon src="assets/images/prev.svg"></svg-icon>
    </ng-template>
    <ng-template ngbPaginationNext>
      <svg-icon src="assets/images/next.svg"></svg-icon>
    </ng-template>
  </ngb-pagination>
</div>
