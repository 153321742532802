<div class="position-relative " (clickOutside)="isShow = false">
  <div class="row">
    <div class="col select pointer" [ngClass]="{'straight-bottom': isShow, 'disabled': disable}"
      (click)="showDropdown()">
      <span *ngFor="let item of items; let i = index" class="item">
        {{translate.currentLang === 'th' ? item.display_name : item.display_name_en}}
        <i class="fas fa-times red pointer " style="z-index: 100;" (click)="deleteItem(i)" *ngIf="multiple"></i></span>
      <span *ngIf="items.length === 0" class="placeholder"> {{ placeholder | translate}}</span>
      <i class="fas fa-caret-down position-absolute grey pt-0" [ngClass]="{'fa-caret-up': isShow}"
        style="right: 0; padding: 10px;"></i>
      <i class="fas fa-times red position-absolute pointer" *ngIf="!multiple && items.length > 0" style="right: 7%;
                top: 12px; font-size: 10px;" (click)="clearValue()"></i>
    </div>
  </div>
  <div class="border dropdown border-top-0" *ngIf="isShow && nodes">
    <tree-root #treeRoot [focused]="true" (activate)="onActivate($event)" (select)="nodeChecked($event.node, true)"
      (deselect)="nodeChecked($event.node, false)" [nodes]="nodes" [options]="options">
      <ng-template #treeNodeTemplate let-node let-index="index">
        <ng-container>
          {{translate.currentLang === 'th' ? node.data.department_name : node.data.department_name_en}}
        </ng-container>
      </ng-template>
    </tree-root>
  </div>
</div>
