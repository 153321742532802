<ng-template #confirmationModal let-c="close" let-d="dismiss" class="modal-dialog" let-modal>
  <div class="modal-header">
    <div class="flex-grow-1 text-center text-uppercase font-18 font-weight-bold">
      {{ 'APPROVAL.YOU-CONFIRM' | translate }} {{ 'APPROVAL.' + actionType.toUpperCase() |translate }} {{ 'APPROVAL.IS-THIS' | translate }}
    </div>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div *ngIf="!enableReason">
      <span>{{"APPROVAL.CONTENT-NO-REQUIRED"|translate}}</span>
    </div>
    <div *ngIf="enableReason">
      <span>{{ reasonLabel | translate }}</span>
      <div class="form-group mt-2">
        <textarea
          class="form-control"
          fz-elastic
          [(ngModel)]="reason"
          (ngModelChange)="onReasonChange()">
        </textarea>
      </div>
    </div>

    <div class="mt-5 text-right">
      <button class="btn mr-2 btn-outline"
        [style.--c]="themeList?.header_table_color"
        [style.--bg]="'#fff'"
        (click)="close()">
        {{"APPROVAL.BACK" | translate}}
      </button>
      <button class="btn" appSetTheme
        type="button"
        [ladda]="isSaved"
        [disabled]="enableReason && !reason"
        (click)="submit()">
        {{"APPROVAL.CONFIRM" | translate}}
      </button>
    </div>
  </div>
</ng-template>
