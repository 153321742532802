import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiService } from 'src/app/core/http/api.service';
import { API_URL } from './api.constant';

export interface SiteConfigObj {
  id: number;
  ddoc_enable: boolean;
  login_adfs_enable: boolean;
  ndid_enable: boolean;
  thaicom_enable: boolean;
  sms_otp_enable: boolean;
  email_otp_enable: boolean;
  ddoc_root_cert_company_name: string;
}

export interface LoanSiteConfigObj {
  loa_id: number;
  upload_memo_type_id: number;
  upload_memo_template_id: number;
}

export type CreditType = 'SHORT_TERM';

export interface LoanSiteConfigResponse extends LoanSiteConfigObj {
  credit_type: CreditType;
}

@Injectable({
  providedIn: 'root',
})
export class SiteConfigService {
  loanConfig: { [key: string]: LoanSiteConfigObj } = {};

  constructor(private http: ApiService) {}

  public getConfig() {
    // this api does not require authentication.
    return this.http.get(API_URL.site_config);
  }

  public loadLoanConfig(): void {
    this.http
      .get<LoanSiteConfigResponse[]>(API_URL.loan_site_config)
      .subscribe((responses) => {
        responses.forEach((res) => {
          const creditType = res.credit_type;
          delete res.credit_type;
          this.loanConfig[creditType] = res;
        });
      });
  }

  public isForbidden(key: string, idToCheck: number): boolean {
    try {
      return Object.values(this.loanConfig)
        .map((obj) => obj[key])
        .includes(idToCheck);
    } catch {
      return false;
    }
  }
}
